/* src/fonts.css */
@font-face {
  font-family: "Futura PT";
  src: url("./components/fuentes/Futura/FuturaStd-Bold7.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FuturaStd-ExtraBold";
  src: url("./components/fuentes/Futura/FuturaStd-ExtraBold.otf")
    format("opentype");
  font-weight: 800; /* No existe 'extra-bold' como valor en CSS, usamos el peso numérico */
  font-style: normal;
}

@font-face {
  font-family: "Futura PT";
  src: url("./components/fuentes/Futura/FuturaStd-Heavy.otf") format("opentype");
  font-weight: 400; /* Normal */
  font-style: normal;
}

@font-face {
  font-family: "FuturaStd-Medium";
  src: url("./components/fuentes/Futura/FuturaStd-Medium.otf")
    format("opentype");
  font-weight: 400; /* Normal */
  font-style: oblique;
}

